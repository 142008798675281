<template>
  <div>
    <!--loading-->
    <vs-progress color="primary" :height="1" v-if="isLoading"/>

    <div class="vx-row mb-3">
      <div class="vx-col lg:w-9/12 w-full">
        <vx-card v-if="isDataInited">
          <vs-tabs alignment="fixed" v-model="activeTab">
            <vs-tab label="Customer" icon-pack="feather">
              <TabCustomer :isActive="activeTab === 0"/>
            </vs-tab>
            <vs-tab label="Booking & Properti" icon-pack="feather">
              <TabProperti :isActive="activeTab === 1"/>
            </vs-tab>
            <vs-tab label="Angsuran" icon-pack="feather">
              <TabAngsuran :isActive="activeTab === 2"/>
            </vs-tab>
            <vs-tab label="Approvals" icon-pack="feather">
              <TabApprovals :isActive="activeTab === 3"/>
            </vs-tab>
          </vs-tabs>
        </vx-card>
      </div>

      <div class="vx-col lg:w-3/12 w-full mt-3 lg:mt-0">
        <!--approval progress-->
        <vx-card>
          <div class="flex items-center mb-base">
            <feather-icon icon="LayersIcon" svgClasses="h-5 w-5" class="mr-2"/>
            <h5 class="font-medium">Approval Progress</h5>
          </div>
          <ApprovalProgress
            ref="approvalProgress"
            type="SKUP-CUSTOMER-KONTRAK"
            :id-ref="$route.params.idBooking"
            :isShowSigningAction="true"
            @signingClicked="onClickSigningAction"/>
        </vx-card>
      </div>
    </div>

    <!--modals-->
    <SkupApprovalSigning :isActive.sync="modalSigning.active"
                         :status="modalSigning.status"
                         @success="onSigned"/>
  </div>
</template>

<script>
import BookingRepository from '@/repositories/marketing/booking-repository'
import moduleBookingDetail from '@/store/modules/marketing/booking/booking-detail.store'

export default {
  name: 'SkupApprovalShow',
  components: {
    TabCustomer: () => import('@/views/pages/marketing/booking/tabs-detail/TabCustomer.vue'),
    TabProperti: () => import('@/views/pages/marketing/booking/tabs-detail/TabProperti.vue'),
    TabAngsuran: () => import('@/views/pages/marketing/booking/tabs-detail/TabAngsuran.vue'),
    TabApprovals: () => import('@/views/pages/marketing/booking/tabs-detail/TabApprovals.vue'),
    SkupApprovalSigning: () => import('@/views/pages/approvals/skup-approval/SkupApprovalSigning'),
    ApprovalProgress: () => import('@/views/components/approval-progress/ApprovalProgress')
  },
  mounted () {
    this.initData()
  },
  data () {
    return {
      isDataInited: false,
      isLoading: false,
      modalSigning: {
        active: false,
        status: null
      }
    }
  },
  computed: {
    storeBookingDetail () {
      return this.$store.state.marketing.bookingDetail
    },
    activeTab: {
      get () {
        return this.storeBookingDetail.activeTab
      },
      set (value) {
        this.$store.commit('marketing/bookingDetail/SET_ACTIVE_TAB', value)
      }
    },
    booking: {
      get () {
        return this.storeBookingDetail.booking
      },
      set (value) {
        this.$store.commit('marketing/bookingDetail/SET_BOOKING', value)
      }
    }
  },
  methods: {
    initData () {
      this.getBooking()
    },

    getBooking () {
      this.isLoading = true

      const idBooking = this.$route.params.idBooking
      BookingRepository.show(idBooking)
        .then(response => {
          this.booking = response.data.data
          this.isDataInited = true
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.$router.push({ name: '404' })
          } else {
            console.log(error)
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    onClickSigningAction (status) {
      this.modalSigning.status = status
      this.modalSigning.active = true
    },

    onSigned () {
      this.initData()
      this.$refs.approvalProgress.refresh()
    }
  },
  beforeCreate () {
    this.$store.registerModule(['marketing', 'bookingDetail'], moduleBookingDetail)
  },
  beforeDestroy () {
    this.$store.commit('marketing/bookingDetail/RESET_STATE')
    this.$store.unregisterModule(['marketing', 'bookingDetail'])
  }
}
</script>
